/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./topnav/topnav.component.ngfactory";
import * as i3 from "./topnav/topnav.component";
import * as i4 from "../app.service";
import * as i5 from "./sidenav/sidenav.component.ngfactory";
import * as i6 from "./sidenav/sidenav.component";
import * as i7 from "./sidenav/sidenav.service";
import * as i8 from "@angular/router";
import * as i9 from "./dashboard.component";
var styles_DashboardComponent = [i0.styles];
var RenderType_DashboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardComponent, data: {} });
export { RenderType_DashboardComponent as RenderType_DashboardComponent };
export function View_DashboardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "flex-container h-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "d-flex flex-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "col-12 p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "topnav", [], null, null, null, i2.View_TopnavComponent_0, i2.RenderType_TopnavComponent)), i1.ɵdid(4, 114688, null, 0, i3.TopnavComponent, [i4.AppService], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "d-flex flex-row h-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "col-1 sidenav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "sidenav", [], null, null, null, i5.View_SidenavComponent_0, i5.RenderType_SidenavComponent)), i1.ɵdid(8, 114688, null, 0, i6.SidenavComponent, [i4.AppService, i7.SidenavService], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "col-11 main-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(11, 212992, null, 0, i8.RouterOutlet, [i8.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 4, 0); _ck(_v, 8, 0); _ck(_v, 11, 0); }, null); }
export function View_DashboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dashboard", [], null, null, null, View_DashboardComponent_0, RenderType_DashboardComponent)), i1.ɵdid(1, 114688, null, 0, i9.DashboardComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardComponentNgFactory = i1.ɵccf("dashboard", i9.DashboardComponent, View_DashboardComponent_Host_0, {}, {}, []);
export { DashboardComponentNgFactory as DashboardComponentNgFactory };
