/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sidenav.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./sidenav.component";
import * as i5 from "../../app.service";
import * as i6 from "./sidenav.service";
var styles_SidenavComponent = [i0.styles];
var RenderType_SidenavComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SidenavComponent, data: { "animation": [{ type: 7, name: "hoverlink", definitions: [{ type: 0, name: "in", styles: { type: 6, styles: { transform: "scale(1)" }, offset: null }, options: undefined }, { type: 0, name: "out", styles: { type: 6, styles: { transform: "scale(1.1)", backgroundColor: "black", opacity: 0.5, borderLeft: "solid", borderColor: "#428bca", color: "red" }, offset: null }, options: undefined }, { type: 1, expr: "in <=> out", animation: { type: 4, styles: { type: 6, styles: {}, offset: null }, timings: "400ms ease-in" }, options: null }], options: {} }] } });
export { RenderType_SidenavComponent as RenderType_SidenavComponent };
function View_SidenavComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [], null, null, null, null, null))], null, null); }
function View_SidenavComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "i", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 1)], function (_ck, _v) { var currVal_1 = _ck(_v, 2, 0, _v.parent.context.$implicit.link); _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.icon, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_SidenavComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "a", [], null, null, null, null, null))], null, null); }
function View_SidenavComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "menu": 0 }), i1.ɵdid(4, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 1), (_l()(), i1.ɵted(6, null, ["", ""]))], function (_ck, _v) { var currVal_2 = _ck(_v, 3, 0, (_v.parent.context.$implicit.scale === "out")); _ck(_v, 2, 0, currVal_2); var currVal_3 = _ck(_v, 5, 0, _v.parent.context.$implicit.link); _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = _v.parent.context.$implicit.label; _ck(_v, 6, 0, currVal_4); }); }
function View_SidenavComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "row flex-column p-0 mb-4"]], [[24, "@hoverlink", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ToggleSelectedLink(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "menu": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "d-flex align-items-center justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidenavComponent_2)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidenavComponent_3)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "col-12 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "d-none d-lg-block"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidenavComponent_4)), i1.ɵdid(12, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidenavComponent_5)), i1.ɵdid(14, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = "row flex-column p-0 mb-4"; var currVal_2 = _ck(_v, 3, 0, (_v.context.$implicit.scale === "out")); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit.disabled; _ck(_v, 6, 0, currVal_3); var currVal_4 = !_v.context.$implicit.disabled; _ck(_v, 8, 0, currVal_4); var currVal_5 = _v.context.$implicit.disabled; _ck(_v, 12, 0, currVal_5); var currVal_6 = !_v.context.$implicit.disabled; _ck(_v, 14, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.scale; _ck(_v, 0, 0, currVal_0); }); }
export function View_SidenavComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "container h-90 p-2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidenavComponent_1)), i1.ɵdid(3, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "qlogo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["class", "img-fluid"], ["src", "../../assets/images/quantaLogoedit.png "]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sideItems; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_SidenavComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sidenav", [], null, null, null, View_SidenavComponent_0, RenderType_SidenavComponent)), i1.ɵdid(1, 114688, null, 0, i4.SidenavComponent, [i5.AppService, i6.SidenavService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidenavComponentNgFactory = i1.ɵccf("sidenav", i4.SidenavComponent, View_SidenavComponent_Host_0, {}, {}, []);
export { SidenavComponentNgFactory as SidenavComponentNgFactory };
