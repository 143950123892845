<div class="flex-container h-100">
    <div class="d-flex flex-row">
        <div class="col-12 p-0">
            <topnav></topnav>
        </div>
    </div>
    <div class="d-flex flex-row h-100">
        <div class="col-1 sidenav">
            <sidenav></sidenav>
        </div>
        <div class="col-11 main-content">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>