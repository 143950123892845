import { TimesheetComponent } from './timesheet/timesheet.component';
import { CrewComponent } from './crew/crew.component';
import { HomeComponent } from './home/home.component';
import { Routes } from '@angular/router';
const ɵ0 = () => import("./asset/asset.module.ngfactory").then(m => m.AssetModuleNgFactory);
const routes = [
    { path: 'home', component: HomeComponent },
    { path: 'timesheet', component: TimesheetComponent },
    { path: 'crew', component: CrewComponent },
    {
        path: 'asset',
        loadChildren: ɵ0,
    }
];
export class AppRoutingModule {
}
export { ɵ0 };
